import { useEffect } from 'react'

import useTracking from 'hooks/useTracking'

import { getLocalStorageItem, removeLocalStorageItem } from 'libs/utils/localStorage'
import { paymentOptionsLoadDurationEvent } from 'libs/common/event-tracker/events'
import { PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY } from 'constants/checkout'

const isPaymentOptionsLoadTimeTrackingDetails = (details: { loadTimeStartStamp: number }) => {
  return 'loadTimeStartStamp' in details
}

const getPaymentOptionsLoadTime = (loadTimeStartStampMS: number) => {
  const loadTimeEndStampMS = Date.now()

  return loadTimeEndStampMS - loadTimeStartStampMS
}

const getLoadTimeStartTrackingDetails = () => {
  const trackingDetailsString = getLocalStorageItem(
    PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
  )
  if (!trackingDetailsString) return null

  removeLocalStorageItem(PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY)

  const trackingDetails = JSON.parse(trackingDetailsString)

  if (isPaymentOptionsLoadTimeTrackingDetails(trackingDetails))
    return {
      loadTimeStartStamp: trackingDetails.loadTimeStartStamp,
    }

  return null
}

const usePaymentOptionsLoadDurationTracking = ({
  checkoutId,
  isPaymentOptionsUILoaded,
}: {
  checkoutId: string | undefined
  isPaymentOptionsUILoaded: boolean
}) => {
  const { track } = useTracking()

  useEffect(() => {
    if (!isPaymentOptionsUILoaded || !checkoutId) return

    const trackingDetails = getLoadTimeStartTrackingDetails()

    if (!trackingDetails) return

    const paymentOptionsLoadTime = getPaymentOptionsLoadTime(trackingDetails.loadTimeStartStamp)

    track(
      paymentOptionsLoadDurationEvent({
        checkout_id: checkoutId,
        duration: paymentOptionsLoadTime,
      }),
    )
  }, [track, checkoutId, isPaymentOptionsUILoaded])
}

export default usePaymentOptionsLoadDurationTracking
